import { Helmet } from 'react-helmet'
import '../styles/Term.css'

const Term = () => {
  return (
    <div>
      <Helmet>
        <title>利用規約</title>
      </Helmet>
      <div className="term-container">
        <div className="term-content-wrapper">
          <strong>■はじめに</strong>
          <br />
          利用規約は、｢Minera(ミネラ)｣におけるサービス(アプリ)を利用する際に、必要な事項を定めたものです。ユーザー登録を行い、その後管理者による承認を受けた方に限り、サービスの提供を受けることができるものとします。ユーザー登録は、利用規約に事前に同意していただくことを条件とします。
          <br />
          <br />
          このアプリ内で不適切な内容を投稿や情報送信すると、事務局がその事実を特定次第、アカウント削除を実施してサービス利用を停止します。
          <br />
          <br />
          <strong>■第1条 利用規約関連用語の定義 ・管理者：Minera運営者</strong>
          <br />
          ・本サービス：｢Minera(ミネラ)｣
          <br />
          ・本サービス：本サービスを通して管理者が提供する文書などの投稿や音声や映像通信機能を持ったサービス(会員サービスを含みます)
          <br />
          ・本規約：この利用規約
          <br />
          ・利用者：本サービスを利用する全ての者(会員を含みます)
          <br />
          ・登録：本規約･プライバシーポリシーに同意し会員サービスの提供を申し込むこと
          <br />
          ・会員：利用者の内、本規約･プライバシーポリシーに同意の上ユーザー登録を行い管理者がサービスの提供を承諾した者
          <br />
          ・会員サービス：利用者の内、会員に限定して提供する｢第11条
          <br />
          本サービスの内容｣に定めるサービス
          <br />
          ・会員登録情報：会員登録や会員サービスの提供を受ける目的で会員が管理者に提供した一切の情報
          <br />
          ・退会：本規約及び管理者が決める方法により、会員登録を抹消し会員サービスの提供を終了すること
          <br />
          ・個人情報：登録情報の内、電話番号など特定の個人を識別できる情報
          <br />
          ・認証情報：登録情報の内、管理者が会員からの接続を認証するために必要な電話番号･パスワード･製造番号等
          <br />
          ・会員掲載コンテンツ:会員により本サービスに掲載もしくはアップロードされる画像･文書などの著作物またはデータなどの全てのコンテンツ
          <br />
          <br />
          <br />
          <strong>■第2条 本規約の適用</strong>
          <ol>
            <li>
              本規約は、本サービスを利用する場合に適用されるものとし、利用者は本規約に従い、かつ、本規約の範囲内でのみ本サービスを利用できるものとします。
            </li>
          </ol>
          <strong>■第3条 本規約の変更</strong>
          <ol>
            <li>
              本規約は、利用者の了承または利用者への通知なくして、追加･変更される場合があります。本規約の追加･変更は、管理者が別途定める場合を除き、本サービス上に掲載表示された時点より効力が生じるものとします。利用者が本規約の変更の効力発生後も本サービスを利用する場合は、変更後の本規約に同意したものとみなします。ただし、別途適用開始時期が定められている場合はこれに従います。
            </li>
          </ol>
          <strong>■第4条 会員</strong>
          <ol>
            <li>
              会員登録希望者は、本規約･プライバシーポリシーを承諾の上で管理者が定める方法により本サービスへの入会を申し込むものとし、会員となった場合には、本規約･プライバシーポリシーを承諾したものとみなします。管理者は、会員登録の申し込みを受け付けた後、必要な審査･手続きを経て会員登録を承認します。尚、会員登録の申し込みがあった場合でも、管理者の審査において、会員とすることに支障があると判断された場合には、会員登録を承認しない場合があります。管理者は会員登録の希望者に対し、当該不承認につき損害賠償義務など法的責任を一切負いません。なお、審査の内容および結果は会員登録の希望者に開示しないものとし、会員登録の希望者は審査の結果について争わないものとします。18歳未満の方及びその年の4月1日までに満18歳を迎えていなかった方は会員登録を行えません。
            </li>
          </ol>
          <strong>■第5条 会員資格の譲渡禁止等</strong>
          
          <ol>
            <li>
              会員は、会員としての資格や権利を第三者に譲渡や貸与したり、あるいは担保の目的として利用することはできません。また、会員としての義務を第三者に承継する事もできません。
            </li>
          </ol>
          
          <strong>■第6条 会員登録情報の変更について</strong>
          
          <ol>
            <li>
              会員は、管理者が定める手続きの過程で管理者に届け出た会員登録情報の内容に変更が生じた場合には、速やかに管理者が別途定める方法で、会員が管理者に変更内容を届け出るものとします。会員が前項の届け出をするまでの間、または届け出を怠ったことにより会員が被った不利益については、管理者は一切責任を負いません。
            </li>
          </ol>
          
          <strong>■第7条 退会</strong>
          
          <ol>
            <li>
              会員が本サービスの利用を終了する場合には、管理者所定の手続きにより、管理者に対して本サービスからの退会届けを提出するものとし、当該退会届けを管理者が受領したときをもって、会員は本サービスから退会したものとします(以下、｢退会会員｣といいます。)。また、登録会員が退会した場合、すでに購入したポイントの換金または返金はしません。
            </li>
            <li>
              管理者は、本サービスの利用(本サービスへのログイン有無を基準とします)が6ヶ月間ない会員を、会員の承諾を得ずに「強制退会」もしくは「ポイント没収」することができるものとします。
              <br />
              サーバーに保存されている退会会員の個人情報や退会会員の過去の書込みなどのデータは、退会会員の意思に関わらず、管理者が抹消することができるものとします。
            </li>
          </ol>
          <strong>■第8条 会員資格の取消</strong>
          
          <ol>
            <li>
              登録会員が以下の項目に該当する場合、管理者は何らの通知、催告をすることなく、一方的に、登録会員の会員資格を取消すことができるものとします。
              <br />
              ・本規約に違反したとき
              ・管理者への届け出内容に虚偽、錯誤があったとき
              <br />
              ・その他、会員として不適当と管理者が判断したとき ・｢第15条
              <br />
              禁止事項｣に定める禁止事項を行った場合　 ・会員が死亡した場合
              <br />
              ・会員登録後に、登録内容に変更があったにもかかわらず、会員がすみやかに変更を行わなかった場合
              <br />
              ・入力されている情報の不正利用または改ざんを行った場合
              <br />
              ・IDまたはパスワードを不正に使用した場合
              <br />
              ・本サービスの利用料金等の支払債務の履行を遅滞し、または支払を拒否した場合
              <br />
              ・本サービスを使用して違法な行為を行った場合
              <br />
              ・その他管理者が、会員として不適当と判断した場合
            </li>
            <li>
              除名処分を受けた会員について、第7条の退会会員に対して行うのと同様の措置を行うものとし、以後原則として本サービスへの会員登録をお断りさせていただくものとします。第1項各号に該当する行為、もしくはこれらに準する行為によって、管理者が何らかの損害を受けた場合は、本サービスの利用の一時停止、または除名処分の如何にかかわらず、該当会員は管理者に対して、かかる損害を運営者と会員との間で協議を行ったうえで賠償するものとします。第1項による除名処分を受けた会員は、除名の理由の開示を求めることができないものとし、除名処分の内容について争わないものとします。本条に定める退会･除名処分により、会員資格は失われ、会員は本サービス利用に関する全ての権利を失うものとします。
            </li>
          </ol>
          <strong>■第9条 利用料金および発行ポイント</strong>
          
          <ol>
            <li>
              本サービスの利用料金およびその支払方法等については、本規約の他、別途弊社が定め、本サービスにて表示する料金規定に従うものとします。
            </li>
            <li>
              利用者は利用料金等に関わる消費税及びその他、賦課される税を負担するものとします。また、振り込み手数料やその他の費用は全て利用者の負担とします。
              <br />
              <br />
              購入したポイントの有効期限は購入日を含め6ヶ月以内です。
            </li>
          </ol>
          
          <strong>■第10条</strong>
          
          IDおよびパスワードの管理
          <br />
          <ol>
            <li>
              会員は、IDならびにパスワードの使用および管理について、一切の責任を負うものとします。また、理由の如何に関わらず、登録会員のIDならびにパスワード等の情報等が他の第三者に使用されたことによる登録会員が被る被害については、本サービスは一切の責任を負わないものとします。
            </li>
            <li>
              登録会員の情報、本サービスの決済方法等、本サービスの会員登録内容に変更があった場合、または自己が設定した情報を失念した場合には、速やかに本サービスの指定の方法で変更の届出をするものとします。また、届け出がない場合及び届け出の不備が原因で登録会員に不利益等の被害が発生したとしても、本サービスは一切の責任を負わないものとします。
            </li>
            <li>
              登録会員が本サービスの利用に際し、第三者に対して損害を与えた場合、登録会員は自己の責任と費用をもって解決し、本サービスに損害を与えないこととします。本サービスの利用により発生した、登録会員または、第三者の損害全てに対して、本サービスは一切その責任を負わないものとし、一切の損害賠償を負う義務のないものとします。
            </li>
            <li>
              本サービスが用意している所定の方法以外でアプリのアンインストールを行なった場合、また機種変更やその他アプリの強制終了等でアプリ情報等が初期化された場合、利用していたアカウントデータ(購入ポイント含む)は抹消され、データの引継ぎは行なえないものとします。またそれによって発生する会員の損失については、管理者は一切の責任を負いません。
            </li>
          </ol>
          <strong>■第11条 本サービスの内容</strong>
          <ol>
            <li>
              本サービスはアプリ内においてメッセージや通話等をお楽しみ頂くサービスであり、男女の異性交際を目的とはしておりません。異性交際希望に関する情報を確認した場合は、事務局にて削除さをしますので、予めご了承ください。
            </li>
            <li>
              管理者は、会員の承諾なく、いつでも提供サービスまたはプログラムの内容を変更する
              <br />
              (改廃を含む)
              <br />
              ことができます。管理者は、以下に記載するような場合に予告なく本サービスのサービスを一時的に中断もしくは停止することができます。
              <br />
              ・天災、火災、停電等により、本サービスの提供が出来なくなった場合
              <br />
              ・本サービス用設備及びシステム等の保守を定期的又は緊急に実施する場合
              <br />
              ・戦争、動乱、暴動、騒乱等により本サービスの提供が不可能になった場合
              <br />
              ・その他、本サービスや一部サービスの一時的な中断が必要と判断した場合
            </li>
            <li>
              管理者は、最低1ヶ月前の予告をもって、管理者の判断により本サービスを停止することができます。この停止予告は、本サービスその他の管理者が提供する手段を通じて発表します。本サービス上で提供されるコンテンツなどの解釈については会員が自ら判断し、その信頼性などについては会員が自己の責任において判断・同意するものとします。
            </li>
          </ol>
          <strong>■第12条 サービスの変更等</strong>
          <ol>
            <li>
              本サービスは、利用者の了承または利用者への通知なくして、適宜その内容や名称等が変更(追加および削減を含みます)され、または中止される場合があります。当該変更または中止につき、管理者は利用者に対し、損害賠償義務など一切の法的責任を負いません。
            </li>
          </ol>
          <strong>■第13条 設備等の準備、維持</strong>
          <ol>
            <li>
              利用者は、自己の費用と責任をもって、本サービスの利用に必要となる機器、ソフトウェア、その他の設備、および回線利用契約の締結、インターネット接続サービスへの加入、その他本サービスの利用に必要となる一切の準備、ならびにその維持を行うものとします。
            </li>
          </ol>
          <strong>■第14条 会員の責任</strong>
          <ol>
            <li>会員は、本規約を遵守して本サービスを利用するものとします。</li>
            <li>
              本サービスの利用にあたり、管理者が定めた手続きがある場合には、利用者はその手続きを経て本サービスを利用するものとします。
            </li>
            <li>
              会員は、本サービスの利用および本サービスの利用を通じて各々が掲載もしくはアップロードした会員掲載コンテンツにつき、一切の責任を負うものとします。
              <br />
              会員が、本規約の違反もしくは｢第15条
              <br />
              禁止事項｣違反などの行為により、他の会員、その他の第三者から要求、クレーム等を受け、または他の会員、その他の第三者に対して要求、クレーム等がある場合には、会員は自己の責任と負担で、これらの要求、クレーム、およびこれに起因する紛争を処理解決するものとし、管理者に一切の迷惑または損害を与えないものとします。また、これら利用者同士のトラブルについて管理者は一切の責任を負わないものとします。
              <br />
              利用者は、本サービスの利用に関連して管理者に損害を与えた場合、運営者と利用者との間で協議を行ったうえで当該損害を賠償するものとします。また、万が一当該利用者の行為により、他の利用者または第三者から管理者が損害賠償請求などを受けた場合、または管理者に社会的損害、経済的損害などが生じた場合は、当該利用者は管理者に対して、管理者に生じた損害および費用(合理的範囲内の弁護士費用などを含みます)を運営者と利用者との間で協議を行ったうえで補償するものとします。
            </li>
            <li>
              本サービスでは、文字や音声や映像によるコミュニケーションの促進という目的から、会員がアップロードした会員掲載コンテンツに関して、他の利用者などによって管理者の所有権や管理の及ばない第三者の管理下にある一部のウェブサイト、ブログ、ホームページなどへの書き出しなどを可能としています。そのため、管理者の管理の及ばないところで、利用者や第三者が不快、不適切または侮蔑的と感じるような情報が表示される可能性があります。そのような侵害行為などにより、会員が被った損害または損失については、管理者は一切の責任を負わないものとし、会員からの申し出に基づくクレームや苦情の処理またはその仲介なども、管理者は一切関与せず、一切の責任も負いません。会員は、本サービスの利用にあたって会員登録した段階で、それらの処理の責任を会員自身ですべて負うことに同意したものとみなします。
            </li>
          </ol>
          <strong>■第15条 禁止事項</strong>
          <ol>
            <li>
              管理者は、本サービスの利用にあたって、会員による以下の行為を禁止します。会員がこれらの禁止行為を行った場合は、管理者は提供している本サービスの各種サービスの一部、若しくは全部の利用停止または、本サービスの会員登録を削除し、以後の利用をお断りさせていただくことがあります。
              <br />
              ・商標権、著作権等の知的財産権、および営業秘密等の情報に関する権利･利益を含む第三者の権利･利益を侵害し、または侵害する恐れのある行為
              <br />
              (たとえば、利用者が開示を行う正当な権利を有しない内容のメッセージやコンテンツのアップロード、掲示、または送信など)を行うこと
              <br />
              ・ウイルスを含むファイル、汚染ファイル、その他、他人のコンピューターの機能に損害を与えるようなソフトウェア、またはプログラムを含むファイルをアップロードし、または送信すること
              <br />
              ・ニックネームや自己紹介に住所、電話番号、メールアドレス等のプライバシーに関わる情報を掲載すること
              <br />
              ・第三者のプライバシーや肖像権を侵害、または侵害する恐れのある行為
              <br />
              (たとえば、第三者の氏名、住所、電話番号、メールアドレス等のプライバシーに関わる情・報を当該第三者が掲載を望まず、または望まないと推測される形態での掲載など)を行うこと
              <br />
              ・第三者を差別し、罵倒、誹謗中傷、脅追を行うこと、その他第三者の名誉や信用を毀損すること
              <br />
              ・中傷･嫌がらせ･暴力的等他の利用者が嫌悪感、不快感を抱く又はその恐れのあるものを本サービスのあらゆる機能やサービスを通じて掲載、開示、提供、送付すること
              <br />
              ・未成年者の健全な育成･成長に害を与える行為、またはその行為を助長させること
              <br />
              ・第三者や他の組織を装い、または第三者や他の組織との関係を偽ること
              <br />
              ・架空または第三者の利用者の個人情報等、虚偽の情報を使用すること
              <br />
              ・事実を誤信させることを目的として、虚偽の内容を掲載すること
              <br />
              ・広告、宣伝、勧誘、他の利用者の妨害、または迷惑行為を助長する行為
              <br />
              ・賭博行為、または賭場を開設し、それらへの参加を促すようなメッセージ送信や情報の掲載を行うこと
              <br />
              ・株式や証券、投資等資産運用に関する情報を特定の投資を勧める目的で掲載すること
              <br />
              ・営業活動、その他本サービス公認のものを除く一切の営利を目的とした利用、またはその準備を目的として利用すること
              <br />
              ・本サービスの提供サービスは異性交際を目的としたものではありません。実際に会うことを強要したり、相手の個人情報を聞き出そうとする事や、またそれに順ずる行為
              <br />
              ・第三者の個人情報を収集したり、蓄積したりすること、またはその恐れのある行為を行うこと
              <br />
              ・各種の法令に違反する、または違反する恐れのある行為を行うこと
              <br />
              ・公序良俗に反する行為、またはその行為を助長させること
              <br />
              ・政治的または宗教的な活動、およびそれに類する内容の掲載や勧誘を行うこと
              <br />
              ・他の利用者による本サービスの利用を妨げること又はその恐れのある行為をすること
              <br />
              ・プログラムのバグ(コンピューターのプログラム上の誤りや不具合)を利用し、本サービスの利用秩序に反する行為を行うこと
              <br />
              ・本サービスにおいて使用されているソフトウェアおよび、データの全部もしくは一部を著作権者の事前同意をなくして、複製、複写、修正、追加、改変および二次利用する行為を行うこと
              <br />
              ・本サービスを構成するすべてのプログラム、ソフトウェアなどに関し、逆アセンブル、逆コンパイルなどの方法でソースコードを解析、翻案を行うこと
              <br />
              ・本サービスの運営を妨害すること若しくは本サービス関連のパートナー企業の信頼を毀損すること、その他の不利益を与えること又はそれらの恐れのある行為をすること
              <br />
              ・日本国内の各種法令に違反する行為、又はそのおそれのある行為
              <br />
              ・IDおよびパスワードを、目的外又は不正に使用する行為
              <br />
              ・本サービスの運営を妨げる行為 ・本サービスの信用を貶める行為
              <br />
              ・18歳未満の方の利用 ・その他管理者が不適切と判断する一切の行為
            </li>
          </ol>
          <strong>■第16条 認証情報の管理</strong>
          <ol>
            <li>
              会員は、認証情報の使用および管理につき責任を負うものとし、会員の故意、過失を問わず、認証情報が第三者に利用されたことに伴う損害は、会員自身が負担するものとし、管理者は一切責任を負担いたしません。会員は自己の認証情報を第三者に利用させ、または第三者と共有することはできません。また、会員は、自己のIDおよびパスワードを、譲渡、貸与、名義変更、担保の目的への提供、その他第三者の利用に供する行為は一切行わないものとします。
            </li>
            <li>
              会員の認証情報により本サービスが利用された場合、管理者は、その利用行為を当該会員自身によって行われたものとみなし、会員ご本人による利用であったかどうかにかかわらず、当該会員が一切の義務と責任を負担するものとします。会員は、自己の認証情報が第三者に利用されている場合、またはそのおそれがある場合、あるいはパスワードを失念した場合には、ただちに管理者に通知し、管理者の指示に従うものとします。
            </li>
          </ol>
          <strong>■第17条 お知らせ(プッシュ通知)配信</strong>
          <ol>
            <li>
              本会員にはお知らせ(プッシュ通知)が配信され、会員はこれを受け取る事に同意したものとみなします。また会員本人がお知らせ(プッシュ通知)の配信を必要としない場合に関しては会員本人が規定の設定変更により(プッシュ通知)配信を停止するものとする。
            </li>
          </ol>
          <strong>■第18条 本サービスの利用制限</strong>
          <ol>
            <li>
              １．以下の各号のいずれかに該当する場合、管理者は、会員の承諾なしに当該会員による本サービスの利用を一時的に制限できるものとします。
              <br />
              ・合理的な努力を尽くしたにもかかわらず、管理者から会員に連絡がとれない場合
              <br />
              ・前号のほか、緊急性が高いと管理者が判断した場合
              <br />
              ・前項の措置がとられたことにより、会員が本サービスを利用できずに会員に損害が発生した場合でも管理者は責任を一切負わないものとします。
            </li>
          </ol>
          <strong>■第19条 会員掲載コンテンツの公開停止･削除</strong>
          <ol>
            <li>
              会員掲載コンテンツについては、管理者が定める期間の経過、情報量の超過、設備の保守管理、本サービスの運営、本規約又は法令違反の疑い、その他管理者の判断により、管理者は、会員に事前の通知をすることなく当該会員の会員掲載コンテンツを公開停止もしくは削除することができるものとし、会員はこれに対し、異議を述べないものとします。ただし、このことは、管理者が当該会員掲載コンテンツの公開停止もしくは削除義務を負うことを意味するものではありません。
            </li>
          </ol>
          <strong>■第20条 本サービスの一時的な中断</strong>
          <ol>
            <li>
              管理者は、次の各号のいずれかの事態が生じた場合、利用者に事前に通知することなく、一時的に本サービスの一部または全部を中断できるものとします。
              <br />
              本サービス用設備の保守の必要がある場合
              <br />
              ・停電、火災等、社会インフラの障害により本サービスが提供できない場合
              <br />
              ・天災、戦争、暴動等の不可抗力で本サービスの提供ができない場合
              <br />
              ・法令に基づく措置により本サービスが提供できない場合
              <br />
              ・その他、運営上、技術上の理由により本サービスの中断が必要であると管理者が判断した場合
            </li>
            <li>
              前各号に基づき本サービスの中断がなされた場合、管理者は、これに起因して生じた利用者、または第三者の損害につき責任を負わないものとします。
            </li>
          </ol>
          <strong>■第21条 管理者の権利</strong>
          <ol>
            <li>
              管理者は、利用者の事前の承諾を必要とせず、本サービスを利用して広告等を掲載する権利を有します。管理者は、利用者の事前の承諾を必要とせず、管理者が適切と判断する方法にて本サービスを分類又は順位をつけて掲載することができるものとします。また、当該分類又は順位に応じて、利用者に種別を設けて、それぞれの利用者に異なるサービス形態で、随時提供できる権利を有します。
            </li>
          </ol>
          <strong>■第22条 サービスの転用の禁止</strong>
          <ol>
            <li>利用者は、本サービスの提供サービスの全部または一部もしくは本サービスの提供サービスにかかるデータを、いかなる方法であれ、他に転用し、第三者に提供もしくは使用させることまたは自己もしくは第三者の営業のために利用することはできないものとします。</li>
          </ol>
          
          <strong>■第23条 免責</strong>
          <ol>
            <li>管理者は、本サービスにより提供される情報について、その完全性、正確性、特定目的への適合性、有用性等、その他一切の内容に関して保証するものではありません。管理者は、以下の事項に関しても一切保証しておりません。
          <br />
          ・本サービスが利用者の希望をみたすこと
          <br />
          ・本サービスの不具合やエラーや障害が生じないこと
          <br />
          ・本サービスから得られる情報等が正確なものであること
          <br />
          ・提供されるアプリケーションの不具合やバグが修正されること
          <br />
          ・管理者は既に支払われた料金、又は購入されたポイントに対して如何なる理由があろうと補償、返金の義務を負わないものとします。本会員が既に購入したポイントを消費している場合にはその範疇とはしない。
          <br />
          ・利用者は自己の責任で本サービスを利用するものとし、管理者に故意または重過失がある場合を除き、利用者が本サービスを利用したことにより利用者に生じた損害については利用者が一切の負担をするものとします。
          <br />
          ・プライバシーポリシーにおいて定める内容を除き、管理者は、利用者に無料で提供しているサービスの利用に関し、いかなる責任も負わないものとします。
          <br />
          ・利用者が未成年者の場合、保護者は本サービスで公開される掲載情報が未成年者にとって適切かどうか判断する責任を負うものとします。
          <br />
          ・管理者は、本サービスの利用におけるFAXによるサポートは原則として行いません。利用者からのご質問などの問い合わせは、アプリ内問い合わせによるもののみとさせていただきます。また、本サービスの利用において、利用者ご本人が使用しているコンピューター、通信機器、通信ソフトなどの一切のサポートは行わないものとします。
          <br />
          ・本サービスに掲載もしくはアップロードした全ての会員掲載コンテンツの管理は、会員が行うものとします。管理者による削除、他者によるによる改ざんを含め、データの紛失、破損その他に関する責任を管理者は負わないものとします。
          <br />
          ・管理者は、本サービス及びパートナーメディア並びに広告主を含む第三者のウェブサイトからのダウンロードやコンピューターウイルス感染等により発生したコンピューター、回線、ソフトウェア等の損害について、賠償する義務を一切負わないものとします。
          </li>
          </ol>
          
          <strong>■第24条 知的財産権の帰属</strong>
          <ol>
            <li>本サービスに関する著作権、商標権、特許権等の知的財産権、その他一切の権利は、管理者に帰属します。本サービスに掲載された一切のコンテンツおよび情報の著作権、著作隣接権、パブリシティ権、商標権、特許権、意匠権、実用新案権及びその他知的財産権並びにその他一切の法的権利及び利益(以下｢知的財産権｣と総称します)は、管理者若しくはコンテンツ提供者に帰属するものとします。会員が本サービスに掲載もしくはアップロードした会員掲載コンテンツの知的財産権は会員本人に帰属します。</li>
            <li>会員は、会員掲載コンテンツを本サービスに掲載又はアップロードした場合、自身が公開する会員掲載コンテンツに第三者の保有する知的財産権が含まれるなどの場合を含め、本サービスの会員掲載コンテンツの使用、利用につき、知的財産権の適法かつ有効な使用権を有していることを保証し、且つ、管理者及び利用者に対し、知的財産権を、永久且つ全世界で、無償にて、非独占的に、利用することを、許諾したものとみなされます。</li>
          </ol>
          
          <strong>■第25条 著作権等の尊重</strong>
          <ol>
            <li>利用者は、本サービスを構成するすべてのプログラム、ソフトウェア、商標、商号又はこれらに付随する一切の知的財産権及びその他の権利が、特段の表示がない限り、管理者又は正当な権限を有する第三者に帰属することを承認します。利用者は、管理者および正当な権利を有する権利者からの許諾なく、本サービスを構成するすべてのプログラム、ソフトウェア等を私的利用の範囲を越えて、複製、貸与、送信可能化、公衆送信、翻案、改変、営業使用することができません。</li>
            <li>利用者は、私的利用の範囲を越えて、本サービスを構成するすべてのプログラム、ソフトウェア等を逆コンパイル、逆アセンブル、リバースエンジニアリング、その他解析、翻案をすることができません。会員が、本サービス上に公開した会員掲載コンテンツに関する知的財産権を、第三者に譲渡、利用許諾又はその他の態様で処分しようとする場合は、当該第三者に対して本規約の内容を承諾させるものとし、当該第三者が承諾しない場合は、当該会員掲載コンテンツに関する知的財産権を譲渡、利用許諾又はその他の態様で処分できないものとします。</li>
          </ol>
          
          <strong>■第26条 個人情報の取り扱い</strong>
          <ol>
            <li>会員登録情報及び管理者で収集した利用者に関する情報は、管理者のプライバシーポリシーに従って取り扱われます。別途に定めるプライバシーポリシーはコチラを必ずお読みください。</li>
          </ol>
          
          <strong>■第27条 管轄裁判所</strong>
          <ol>
            <li>本規約に関する訴訟については、大阪地方裁判所を第一審の専属的合意管轄裁判所とするものとします。</li>
          </ol>
          
          <strong>■第28条 準拠法</strong>
          <ol>
            <li>本規約に関する準拠法は日本法とします｡</li>
          </ol>
          
        </div>
      </div>
    </div>
  )
}

export default Term
